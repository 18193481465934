import React from "react"
import { renderFretboardMode } from '../utils/engine';
import './diagram.css';

export const Note = ({ length, radius, isRootNote }) => (
  <svg width={length} height={length} version="1.1" xmlns="http://www.w3.org/2000/svg">
    <circle cx={length / 2} cy={length / 2} r={radius} fill="currentColor" />
    { isRootNote &&
      <text x="28%" y="55%" stroke="white" stroke-width="2px" dy=".25em" 
        fontFamily="Arial, sans-serif"
      >
        R
      </text>
    }

  </svg>
);

export const HalfStep = ({ length }) => (
    <svg 
      width={length} height={length} 
      version="1.1" xmlns="http://www.w3.org/2000/svg">
      <line x1={length/2} x2={length/2} y1="0" y2={length} 
      stroke="currentColor" strokeWidth="7"/>
    </svg>
);

const Diagram = ({ mode, string }) => {
  let keyCount = 0;
  const length = 30;
  const radius = 15;
      console.log(length, radius);
  // Ugh...pretty hacky: Hard-coded check for first root (R) in the engine code.
  // Should just pass Note, HalfStep, and RootNote to the renderer
  return (
    <div className="modal-string-diagram">
      {renderFretboardMode(mode, 
        (isRoot) => <>
              <Note 
                length={length} 
                radius={radius} 
                isRootNote={isRoot} 
              />
              <HalfStep length={length}/>
        </>,
        (isRoot) => <Note length={length} radius={radius} isRootNote={isRoot} />,
        () => <HalfStep length={length} />
      ).map(elem => {
        return { ...elem, key: `step-${keyCount++}` }
      })
      }
    </div>
  );
}

export default Diagram
