import React from "react"
import './ModeCard.css'

const ModeCard = ({ mode }) => {
  const alias = mode.aliases.length > 0 && mode.aliases[0];
  return (
    <div className="mode-card">
      <div>{mode.name}</div>
      <div>{alias ? `(${alias})` : `--`}</div>
      <div>{mode.seventh}</div>
    </div>
  );
}

export default ModeCard
