const { Mode, Scale, Interval } = require('@tonaljs/tonal');
const _ = require('lodash');

export function randomMode() {
  const randomModeSeed = Math.random();
  const modeIndex = Math.round(randomModeSeed * 6);
  const selectedMode = Mode.all()[modeIndex];
  return selectedMode;
}

export function randomString() {
  const randomStringSeed = Math.random();
  const strings = ['Low E', 'A', 'D', 'G', 'B', 'High E']

  const stringIndex = Math.round(randomStringSeed * 5);
  return strings[stringIndex];
}


// NOTE: This assumes key of C!!! (Much hard-coding)
export function getTonalCenter(modeName) {
  switch (modeName) {
    case 'ionian': return 'C';
    case 'dorian': return 'D';
    case 'phrygian': return 'E';
    case 'lydian': return 'F';
    case 'mixolydian': return 'G';
    case 'aeolian': return 'A';
    case 'locrian': return 'B';
    default: return 'BLERGH';
  }
}

// Accepts a @tonaljs/tonal mode object
export function renderFretboardMode(mode, 
  renderWholestep, renderHalfstep, renderFinalWholestep, renderRoot
) {
  if (!renderWholestep || !renderHalfstep) {
    return "Missing parameters: renderWholestep or renderHalfstep functions";
  }
  // NOTE: Mick says we should only use key of C in the beginning (no sharps/flats)
  const keyOfC = Scale.get("C ionian"); // =>["C", "D", "E", "F", "G", "A", "B"];

  
  const tonalCenter = keyOfC.notes[mode.modeNum];
  const scale = Scale.get(`${tonalCenter} ${mode.name}`); 
  const notes = scale.notes;
  // console.log('notes: ', scale.notes.map(n => n));
  // console.log(notes);
  const intervals = _.range(0, 6)
    .map(idx => ({ a: notes[idx], b: notes[idx + 1] }))
    .map(obj => Interval.distance(obj.a, obj.b));

  // Ehh, kinda hacky t check for root note
  const output = intervals.map((i, idx) => {
    let isRoot = idx === 0;
    if (i === '2M') {
      return renderWholestep(isRoot); // `\no\n|`;
    } else {
      return renderHalfstep(isRoot); // `\no`;
    }
  });

  // Print the final "note" since intervals is only in-between
  output.push(renderHalfstep()); 

  // console.log('Tonal Center: ', notes[0]);
  const toNextOctave = Interval.distance(notes[6], notes[0]);
  if (toNextOctave === '2M') {
    output.push(renderFinalWholestep());
  }
  return output;

}

// TODO: Consolidate to a function (one that takes an optional 
// param to do it side-ways?
function printFretboardMode(mode) {
  return renderFretboardMode(mode, 
    () => "\no\n|",
    () => "\no", 
    () => '\n|'
  );
}

const mode = randomMode();
const string = randomString();
printFretboardMode(mode);

console.log (`\nPlay the ${mode.name} scale on the ${string} string.`);
console.log(
  'The pattern below should give you a hint on how to play the mode on a single string'
);

// const output = renderFretboardMode(mode);
// console.log('Output: ');
// output.forEach(elem => console.log(elem));
