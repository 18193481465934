import React from "react"
import { Mode } from '@tonaljs/tonal';

import Layout from "../components/layout"
import ModeCard from '../components/ModeCard';
import SEO from "../components/seo"
import Diagram, { Note, HalfStep } from '../components/Diagram';
import './western-modes.css';

const IndexPage = () => {

  return (
    <Layout>
      <SEO title="Musical Modes" />
      <p className="modes-chart-summary">
        A spatial representation of the notes in each Western diatonic 
        mode on a single guitar string. Each <Note length={30} radius={15} /> 
        indicates a fretted note, and the
        <HalfStep length={30} /> equals a whole step (or skipped fret).
        The top <Note length={30} radius={15} isRootNote={true} />
        indicates the root (or tonal center) of the mode).
      </p>
      <div className="modes-gallery">
        {Mode.all().map(mode => 
            <div>
              { false && <code> {JSON.stringify(mode, undefined, '\n')}</code> }
          <ModeCard mode={mode} />
          <div>
            <Diagram 
              mode={mode} 
            />
          </div>
        </div>
        )}
      </div>

    </Layout>
  );
}

export default IndexPage
